
.search-container {
    position: fixed;
    width: 100%;
    top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.search-input {
    padding: 0.5rem 2rem;
    appearance: none;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    width: 512px;
    max-width: 90%;
    margin: 0 auto;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.4));
}

.results-container {
    position: fixed;
    width: 512px;
    top: 6rem;
    background-color: white;
    max-width: 90%;
    margin: 0 auto;
    border-radius: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
}