#map {
  height: 100vh;
}

h1 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.mb-4 {
  margin-bottom: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

small {
  font-size: 0.75rem;
  color: #707070;
}

.leaflet-container a.status-permitted, .status-permitted {
  color: green;
}

.leaflet-container a.status-tbd, .status-tbd {
  color: orange;
}

.leaflet-container a.status-not-permitted, .status-not-permitted {
  color: red;
}
.stratton-logo {
  width: 128px;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

@media screen and (min-width: 512px) {
  .stratton-logo {
    width: 256px;
  }
}